import React from 'react';
import {
  Footer,
  FooterText,
  Header,
  HeaderContent,
  HeaderSubtitle,
  HeaderText,
  HeaderTitle,
  Page,
  Section,
  SectionText,
  SectionTitle,
} from './HomepageStyle';

const Homepage = () => {
  return (
    <Page>
      <Header>
        <HeaderContent>
          <HeaderTitle>
            Five<span>Stars</span>
          </HeaderTitle>
          <HeaderSubtitle>AI-Powered Review Management for Five-Star Success</HeaderSubtitle>
          <HeaderText>Coming soon...</HeaderText>
        </HeaderContent>
      </Header>
      <Section>
        <SectionTitle>Welcome to FiveStars</SectionTitle>
        <SectionText>
          Five Stars is a startup leveraging AI to master online review management. Currently in
          beta, our platform analyzes and improves customer feedback for businesses, aiming to boost
          ratings and credibility. A public launch is imminent, setting a new standard for
          reputation excellence.
        </SectionText>
      </Section>
      <Footer>
        <FooterText>&copy; 2023 FiveStars | AI-Powered Review Management</FooterText>
      </Footer>
    </Page>
  );
};

export default Homepage;
