import styled from 'styled-components';

export const Page = styled.div``;

export const Header = styled.header`
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url(https://res.cloudinary.com/dq54sjou4/image/upload/f_auto,q_auto/ribco0iipgsbgdo95kkg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  background-color: rgb(31, 31, 31);
  height: 85vh;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  margin: 32vh auto;
`;

export const HeaderTitle = styled.div`
  font-size: 5rem;
  margin: 0;
  font-family: 'Kanit', sans-serif;
  font-weight: 100;
  z-index: 1;
  & span {
    font-weight: 500;
    color: #fbd784;
  }
`;

export const HeaderSubtitle = styled.p`
  font-size: 1.5rem;
  margin: 0;
  /* color: #fbd784; */
`;

export const HeaderText = styled.p`
  font-size: 2rem;
  margin-top: 4rem;
`;

export const Section = styled.section`
  padding-top: 15vh;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  background-image: linear-gradient(180deg, rgba(11, 29, 38, 0), #0b1d26 20%);
`;

export const SectionTitle = styled.div`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  & span {
    color: #fbd784;
  }
`;

export const SectionText = styled.p`
  font-size: 1.2rem;
  width: 50%;
  margin: auto;
  text-align: center;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  margin-bottom: 2rem;
`;

export const Footer = styled.footer`
  color: #fff;
  text-align: center;
  padding: 1em 0;
`;

export const FooterText = styled.p``;
